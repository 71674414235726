import styled from "styled-components";

export const StyledFloatNav = styled.nav`
  z-index: 10000;
  position: fixed;

  height: 100vh;
  width: ${(props) => (props.fullcover ? "100vw" : "40vw")};

  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;

  background: ${({ theme }) => theme.dyphiBlueText};
  transform: ${({ open }) => (open ? "translateX(0%)" : "translateX(200%)")};

  top: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;

  font-family: NanumSquare;
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;

  .menu {
    height: 85vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-top: 5rem;

    a,
    button {
      text-align: ${(props) => (props.fullcover ? "center" : "left")};
      font-family: inherit;
      font-style: inherit;
      font-weight: inherit;
      font-size: inherit;
      color: ${({ theme }) => theme.dyphiWhite};
      text-decoration: none;
      transition: color 0.3s linear;

      &:hover {
        color: ${({ theme }) => theme.dyphiBlue};
      }
    }
  }

  .locale {
    position: fixed;
    right: 5vw;
    bottom: ${(props) => (props.fullcover ? "15vh" : "10vh")};

    border: 2px solid #ffffff;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-self: flex-end;
    padding: 8px;

    font-size: 1rem;
    font-weight: inherit;

    button {
      font-family: inherit;
      font-style: inherit;
      font-weight: inherit;
      font-size: inherit;
      padding: 0px 10px;
      align-self: center;
    }

    span {
      font-family: inherit;
      font-style: inherit;
      font-weight: inherit;
      font-size: inherit;
      color: ${({ theme }) => theme.dyphiInactive};
      align-self: center;
    }
  }
`;
