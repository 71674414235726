import React from "react";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { selectLocale } from "../../../lang/localeSlice";
import { DesktopMinWidth } from "../../ScreenChecker";
import { FormattedMessage } from "react-intl";
import { Element } from "react-scroll";
import { Fade } from "react-awesome-reveal";

import { StyledPartner } from "./Partner.styled";

const Partner = () => {
  const isDesktop = useMediaQuery({ minWidth: DesktopMinWidth });
  const locale = useSelector(selectLocale);

  return (
    <Element name="partner">
      <StyledPartner isDesktop={isDesktop}>
        <div className="title">
          <Fade delay={0} triggerOnce={true} direction="down">
            <FormattedMessage id="partner_header" />
          </Fade>
        </div>

        <div className="line"></div>

        <div className="logos">
          {/* 서울아산병원 */}
          <img
            style={{ width: 300 }}
            src={
              process.env.PUBLIC_URL +
              (locale === "ko-KR"
                ? "/img/partner/logo-asan.svg"
                : "/img/partner/logo-asan.svg")
            }
            alt="Seoul Asan Medical Center"
          />
          {/* 서울아산병원 연구중심병원사업단 */}
          <img
            style={{ width: 280 }}
            src={
              process.env.PUBLIC_URL +
              (locale === "ko-KR"
                ? "/img/partner/logo-asan-hct.svg"
                : "/img/partner/logo-asan-hct.svg")
            }
            alt="Seoul Asan Medical Center HCT"
          />
          {/* 서울대병원 */}
          <img
            style={{ width: 280 }}
            src={
              process.env.PUBLIC_URL +
              (locale === "ko-KR"
                ? "/img/partner/logo-snuh-kr.svg"
                : "/img/partner/logo-snuh-en.svg")
            }
            alt="Seoul National University Hospital"
          />
          {/* 중앙대병원 */}
          <img
            style={{ width: 360 }}
            src={
              process.env.PUBLIC_URL +
              (locale === "ko-KR"
                ? "/img/partner/logo-cauh.svg"
                : "/img/partner/logo-cauh.svg")
            }
            alt="Chung-Ang University Hospital"
          />
        </div>
      </StyledPartner>
    </Element>
  );
};

export default Partner;
