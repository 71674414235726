import { createSlice } from "@reduxjs/toolkit";

export const localeSlice = createSlice({
  name: "locale",
  initialState: {
    value: "ko-KR", // "en-US"
  },
  reducers: {
    changeLocale: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { changeLocale } = localeSlice.actions;

export const selectLocale = (state) => state.locale.value;

export default localeSlice.reducer;
