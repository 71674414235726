import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { IntlProvider } from "react-intl";
import { selectLocale } from "./lang/localeSlice";
import {
  Menu,
  Home,
  About,
  AndanteFit,
  Publication,
  Partner,
  Contact,
  PrivateAndCookie,
} from "./components";
import { GlobalStyles } from "./global";
import { theme } from "./theme";
import en from "./lang/en-US.json";
import kr from "./lang/ko-KR.json";

const MESSAGES = {
  "en-US": en,
  "ko-KR": kr,
};

function App() {
  const locale = useSelector(selectLocale);

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <IntlProvider
          locale={locale}
          defaultLocale="en-US"
          messages={MESSAGES[locale]}
        >
          <Router>
            <Switch>
              <Route exact path="/">
                <Menu />
                <Home />
                <About />
                <AndanteFit />
                <Publication />
                <Partner />
                <Contact />
              </Route>

              <Route exact path="/private-and-cookie">
                <PrivateAndCookie />
              </Route>
            </Switch>
          </Router>
        </IntlProvider>
      </>
    </ThemeProvider>
  );
}

export default App;
