import styled from "styled-components";

export const StyledBurger = styled.div`
  z-index: 10001;
  position: fixed;
  top: 5%;
  right: 2rem;

  border: none;
  border-radius: 100px;
  border-color: ${({ open, theme }) =>
    open ? "transparent" : theme.dyphiWhite};
  background-color: ${({ open, theme }) =>
    open ? "transparent" : "rgba(0, 0, 0, 0.3)"};

  padding: 15px;

  display: flex;
  justify-content: center;
  align-items: center;

  button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    cursor: pointer;
    padding: 0;

    span {
      width: 2rem;
      height: 0.25rem;
      background: ${({ open, theme }) =>
        open ? theme.dyphiBlack : theme.dyphiWhite};
      border-radius: 10px;
      transition: all 0.3s linear;
      position: relative;
      transform-origin: 1px;

      :first-child {
        transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
      }

      :nth-child(2) {
        opacity: ${({ open }) => (open ? "0" : "1")};
        transform: ${({ open }) =>
          open ? "translateX(20px)" : "translateX(0)"};
      }

      :nth-child(3) {
        transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
      }
    }
  }
`;
