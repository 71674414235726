import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeLocale, selectLocale } from "../../../lang/localeSlice";
import { FormattedMessage } from "react-intl";
import { scroller } from "react-scroll";
import { useDisableScroll } from "../../../hooks";
import { StyledFloatNav } from "./FloatNav.styled";

const FloatNav = ({ open, setOpen, ...props }) => {
  const isHidden = open ? true : false;

  const locale = useSelector(selectLocale);
  const dispatch = useDispatch();
  const onClickLocaleButton = useCallback(
    (value) => {
      dispatch(changeLocale(value));
    },
    [dispatch]
  );

  useDisableScroll(open);

  const scrollTo = (elementID) => {
    scroller.scrollTo(elementID, { duration: 800, smooth: true });
    setOpen(!open);
  };

  return (
    <StyledFloatNav
      open={isHidden}
      fullcover={props.fullcover}
      aria-hidden={!isHidden}
      {...props}
    >
      <div className="menu">
        <button onClick={() => scrollTo("home")}>
          <FormattedMessage id="nav_home" />
        </button>
        <button onClick={() => scrollTo("about")}>
          <FormattedMessage id="nav_about" />
        </button>
        <button onClick={() => scrollTo("andantefit")}>
          <FormattedMessage id="nav_solution" />
        </button>
        <button onClick={() => scrollTo("publication")}>
          <FormattedMessage id="nav_publication" />
        </button>
        <a
          href="https://www.notion.so/dyphi/Career-bcc9b8ceb6ac4beb9b15a5394abfa595"
          target="_blank"
          rel="noreferrer"
        >
          <FormattedMessage id="nav_career" />
        </a>
        <a
          href="https://www.notion.so/dyphi/3e2a183f20c144958164de64614dd898?v=1d55a7509d68413492866a5c48fb4859"
          target="_blank"
          rel="noreferrer"
        >
          <FormattedMessage id="nav_ir" />
        </a>
      </div>

      <div className="locale">
        <button
          style={{ color: locale === "ko-KR" ? "#ffffff" : "#9c9c9c" }}
          onClick={() => {
            onClickLocaleButton("ko-KR");
            setOpen(!open);
          }}
        >
          KR
        </button>
        <span>|</span>
        <button
          style={{ color: locale === "en-US" ? "#ffffff" : "#9c9c9c" }}
          onClick={() => {
            onClickLocaleButton("en-US");
            setOpen(!open);
          }}
        >
          EN
        </button>
      </div>
    </StyledFloatNav>
  );
};

export default FloatNav;
