import React from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { DesktopMinWidth } from "../../ScreenChecker";
import { FormattedMessage } from "react-intl";
import { Element } from "react-scroll";
import { Fade } from "react-awesome-reveal";

import { StyledContact } from "./Contact.styled";

const Contact = () => {
  const isDesktop = useMediaQuery({ minWidth: DesktopMinWidth });

  return (
    <Element name="contact">
      <StyledContact isDesktop={isDesktop}>
        <div className="items">
          <div className="item">
            <div className="title">
              <Fade delay={0} triggerOnce={true}>
                <FormattedMessage id="contact_header1" />
              </Fade>
            </div>
            <div className="content">
              <Fade delay={0} triggerOnce={true}>
                <FormattedMessage id="contact_content1_1" />
              </Fade>
            </div>
            <div className="content">
              <Fade delay={0} triggerOnce={true}>
                <FormattedMessage id="contact_content1_2" />
              </Fade>
            </div>
          </div>

          <div className="item">
            <div className="title">
              <Fade delay={0} triggerOnce={true}>
                <FormattedMessage id="contact_header2" />
              </Fade>
            </div>
            <a href="mailto:info@dyphi.com" className="content">
              <Fade delay={0} triggerOnce={true}>
                <FormattedMessage id="contact_content2_1" />
              </Fade>
            </a>
          </div>
        </div>

        <div className="footer">
          <div className="copyright">
            <FormattedMessage id="contact_copyright" />
          </div>

          {/* <div className="temrs-and-conditions">
            <Link to="/temrs-of-service" className="text">
              <FormattedMessage id="contact_terms-of-service" />
            </Link>

            {isDesktop && <span className="sep">|</span>}

            <Link to="/private-and-cookie" className="text">
              <FormattedMessage id="contact_private-and-cookie" />
            </Link>
          </div> */}
        </div>
      </StyledContact>
    </Element>
  );
};

export default Contact;
