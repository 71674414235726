export const theme = {
  dyphiBlue: "#598CEF",
  dyphiBlueText: "#5877ED",
  dyphiBlueDark: "#4E7CD3",
  dyphiBlack: "#000000",
  dyphiWhite: "#ffffff",

  dyphiInactive: "#c8c8c8",
  dyphiLightGray: "#f8f8f8",
  dyphiDarkGray: "#3C3C3C",
};
