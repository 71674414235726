import React, { useState, useRef } from "react";
import { useOnClickOutside } from "../../hooks";
import { useMediaQuery } from "react-responsive";

import { StyledMenu } from "./Menu.styled";

import {
  TopNav,
  Burger,
  FloatNav,
  DesktopMinWidth,
  MobileMaxWidth,
} from "../../components";

const Menu = () => {
  const isDesktop = useMediaQuery({ minWidth: DesktopMinWidth });
  const isMobile = useMediaQuery({ maxWidth: MobileMaxWidth });

  const [open, setOpen] = useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setOpen(false));

  return (
    <StyledMenu>
      {isDesktop ? (
        <TopNav />
      ) : (
        <div ref={node}>
          <Burger open={open} setOpen={setOpen} />
          <FloatNav
            open={open}
            setOpen={setOpen}
            fullcover={isMobile}
            alignCenter={false}
          />
        </div>
      )}
    </StyledMenu>
  );
};

export default Menu;
