import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeLocale, selectLocale } from "../../../lang/localeSlice";
import { FormattedMessage } from "react-intl";
import { scroller } from "react-scroll";
import { useScroll } from "../../../hooks";

import { StyledTopNav } from "./TopNav.styled";

const TopNav = ({ open, ...props }) => {
  const locale = useSelector(selectLocale);
  const dispatch = useDispatch();
  const onClickLocaleButton = useCallback(
    (value) => {
      dispatch(changeLocale(value));
    },
    [dispatch]
  );

  const { y } = useScroll();
  const scrollTo = (elementID) => {
    scroller.scrollTo(elementID, { duration: 800, smooth: true });
  };

  return (
    <StyledTopNav scrolled={y > 0 ? 1 : 0}>
      <div className="menu-box">
        <img
          scrolled={y > 0 ? 1 : 0}
          src={
            process.env.PUBLIC_URL +
            (y > 0 ? "/img/dyphi-logo-black.svg" : "/img/dyphi-logo-white.svg")
          }
          alt="DYPHI logo"
          onClick={() => scrollTo("home")}
        />
        <button onClick={() => scrollTo("about")}>
          <FormattedMessage id="nav_about" />
        </button>
        <button onClick={() => scrollTo("andantefit")}>
          <FormattedMessage id="nav_solution" />
        </button>
        <button onClick={() => scrollTo("publication")}>
          <FormattedMessage id="nav_publication" />
        </button>
        <a
          href="https://www.notion.so/dyphi/Career-bcc9b8ceb6ac4beb9b15a5394abfa595"
          target="_blank"
          rel="noreferrer"
        >
          <FormattedMessage id="nav_career" />
        </a>
        <a
          href="https://www.notion.so/dyphi/3e2a183f20c144958164de64614dd898?v=1d55a7509d68413492866a5c48fb4859"
          target="_blank"
          rel="noreferrer"
        >
          <FormattedMessage id="nav_ir" />
        </a>
      </div>

      <div className="misc-box">
        <div className="locale">
          <button
            style={{ color: locale === "ko-KR" ? "inherit" : "#c8c8c8" }}
            onClick={() => onClickLocaleButton("ko-KR")}
          >
            KR
          </button>
          <span>|</span>
          <button
            style={{ color: locale === "en-US" ? "inherit" : "#c8c8c8" }}
            onClick={() => onClickLocaleButton("en-US")}
          >
            EN
          </button>
        </div>
        <button className="contact" onClick={() => scrollTo("contact")}>
          <FormattedMessage id="nav_contact" />
        </button>
      </div>
    </StyledTopNav>
  );
};

export default TopNav;
