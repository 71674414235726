import styled from "styled-components";

export const StyledPublication = styled.div`
  background-color: ${({ theme }) => theme.dyphiBlack};
  color: ${({ theme }) => theme.dyphiWhite};
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  padding: ${(props) => (props.isDesktop ? "20vh 0" : "10vh 0")};

  font-family: NanumSquare;
  font-style: normal;

  .title {
    font-family: inherit;
    font-style: inherit;
    font-weight: 800;
    font-size: 3.5rem;
    letter-spacing: 0.1rem;
    text-align: center;
  }

  .line {
    width: 60vw;
    border-top: 1.5px solid;
    border-color: ${({ theme }) => theme.dyphiWhite};
  }

  .items {
    width: 70vw;
    display: flex;
    flex-direction: ${(props) => (props.isDesktop ? "row" : "column")};
    justify-content: space-around;
    align-items: stretch;

    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      padding: 5vh 0;

      .value {
        color: inherit;
        line-height: 5rem;
        letter-spacing: 0.1rem;
        font-family: inherit;
        font-style: inherit;
        font-weight: 700;
        font-size: 3rem;
        text-align: center;
        word-break: keep-all;
      }

      .suffix {
        color: inherit;
        line-height: 2rem;
        letter-spacing: 0.1rem;
        font-family: inherit;
        font-style: inherit;
        font-weight: 400;
        font-size: 1.5rem;
        text-align: center;
        word-break: keep-all;
      }
    }
  }

  .linkToMore {
    text-align: center;
    padding-top: 10vh;

    a {
      text-decoration: none;
      font-family: inherit;
      font-style: inherit;
      font-weight: 400;
      font-size: 1.2rem;
      color: ${({ theme }) => theme.dyphiBlueText};
    }
  }
`;
