import React from "react";
import { useMediaQuery } from "react-responsive";
import { DesktopMinWidth } from "../../ScreenChecker";
import { FormattedMessage } from "react-intl";
import { Element } from "react-scroll";
import { Fade } from "react-awesome-reveal";

import { StyledAndanteFit } from "./AndanteFit.styled";

const AndanteFit = () => {
  const isDesktop = useMediaQuery({ minWidth: DesktopMinWidth });

  return (
    <Element name="andantefit">
      <StyledAndanteFit isDesktop={isDesktop}>
        <div className="leftContent">
          <Fade cascade triggerOnce={true} direction="down">
            <div className="logo">
              <img
                src={process.env.PUBLIC_URL + "/img/andantefit-logo-black.svg"}
                alt="AndanteFit Logo"
              />
            </div>
          </Fade>
          <div className="content">
            <Fade cascade triggerOnce={true}>
              <div className="header">
                <FormattedMessage id="andantefit_header1_1" />
                <br></br>
                <FormattedMessage id="andantefit_header1_2" />
              </div>
            </Fade>
            <Fade cascade triggerOnce={true}>
              <div className="text">
                <FormattedMessage id="andantefit_text1" />
              </div>
              <div className="text">
                <FormattedMessage id="andantefit_text2" />
              </div>
              <div className="text">
                <FormattedMessage id="andantefit_text3" />
              </div>
              {/* <div className="linkToMore">
                <a href="/" target="_blank">
                  <FormattedMessage id="andantefit_more" />
                  <span> ></span>
                </a>
              </div> */}
            </Fade>
          </div>
        </div>

        <div className="rightGraphics">
          <img
            src={process.env.PUBLIC_URL + "/img/andantefit-graphics.png"}
            alt="AndanteFit Graphics"
          />
        </div>
      </StyledAndanteFit>
    </Element>
  );
};

export default AndanteFit;
