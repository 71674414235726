import styled from "styled-components";

export const StyledSolution = styled.div`
  background-color: ${({ theme }) => theme.dyphiBlack};
  color: ${({ theme }) => theme.dyphiWhite};
  min-height: 100vh;

  display: flex;
  justify-content: center;
  align-items: stretch;

  font-family: NanumSquare;
  font-style: normal;
  font-weight: 700;

  .grid {
    padding: ${(props) =>
      props.isDesktop ? "20vh 1vw" : "20vh 10vw 10vh 10vw"};
    width: 100vw;

    display: grid;
    align-content: stretch;
    justify-content: stretch;

    grid-template-columns: ${(props) =>
      props.isDesktop ? "repeat(3, 1fr)" : "repeat(1, 1fr)"};
    grid-template-rows: ${(props) =>
      props.isDesktop ? "repeat(3, 1fr)" : "repeat(9, 1fr)"};
    gap: ${(props) => (props.isDesktop ? "15vh 8.3333vw" : "10vh 0vw")};

    grid-template-areas: ${(props) =>
      props.isDesktop
        ? `
          "header1 text1 text1"
          "text2 text2 header2"
          "header3 text3 text3"
          `
        : `
          "header1"
          "text1"
          "text1"
          "header2"
          "text2"
          "text2"
          "header3"
          "text3"
          "text3"
          `};
  }
`;

export const StyledHeader = styled.div`
  align-self: center;
  line-height: 6rem;
  letter-spacing: 0.1rem;

  font-family: inherit;
  font-style: inherit;
  font-weight: 800;
  font-size: 4rem;
  word-break: keep-all;

  grid-area: ${(props) => "header" + String(props.order)};
  text-align: ${(props) =>
    props.isDesktop ? (props.order % 2 === 0 ? "left" : "right") : "center"};
`;

export const StyledText = styled.div`
  align-self: center;

  font-family: inherit;
  font-style: inherit;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2rem;
  letter-spacing: 0.1rem;
  word-break: keep-all;

  align-self: ${(props) => (props.isDesktop ? "center" : "flex-start")};
  grid-area: ${(props) => "text" + String(props.order)};
  text-align: ${(props) =>
    props.isDesktop ? (props.order % 2 === 0 ? "right" : "left") : "center"};
`;
