import styled from "styled-components";

export const StyledHome = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  font-family: NanumSquare;
  font-style: normal;

  background: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)),
    url(${(props) => props.backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;

  .title {
    color: ${({ theme }) => theme.dyphiWhite};
    width: 40vw;
    line-height: ${(props) => (props.isDesktop ? "10rem" : "7rem")};
    letter-spacing: 0.1rem;
    margin-left: 10%;

    font-family: inherit;
    font-style: inherit;
    font-weight: 700;
    font-size: ${(props) => (props.isDesktop ? "5rem" : "4em")};
    word-break: keep-all;
  }
`;
