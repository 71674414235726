import styled from "styled-components";

export const StyledPartner = styled.div`
  background-color: ${({ theme }) => theme.dyphiWhite};
  color: ${({ theme }) => theme.dyphiBlack};
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding: ${(props) => (props.isDesktop ? "20vh 0" : "10vh 0")};

  font-family: NanumSquare;
  font-style: normal;

  .title {
    font-family: inherit;
    font-style: inherit;
    font-weight: 800;
    font-size: 3.5rem;
    letter-spacing: 0.1rem;
    text-align: center;
    line-height: 8rem;
  }

  .line {
    width: 60vw;
    border-top: 1.5px solid;
    border-color: ${({ theme }) => theme.dyphiBlack};
  }

  .logos {
    max-width: 100%;
    flex-wrap: wrap;

    display: flex;
    flex-direction: ${(props) => (props.isDesktop ? "row" : "column")};
    justify-content: center;
    align-items: center;

    img {
      padding: ${(props) => (props.isDesktop ? "10% 5%" : "20% 10%")};
    }
  }
`;
