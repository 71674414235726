import styled from "styled-components";

export const StyledTopNav = styled.div`
  z-index: 10000;
  position: fixed;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: ${(props) =>
    props.scrolled ? "rgba(255, 255, 255, 1.0)" : "transparent"};
  color: ${(props) => (props.scrolled ? "black" : "white")};
  transition: background-color 0.3s ease-in-out;

  font-family: NanumSquare;
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;

  padding: 15px 0;

  .menu-box {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    img {
      width: auto;
      height: 20px;
      padding: 0 60px;
      cursor: pointer;
    }

    a,
    button {
      padding: 0 2.5vw;
      font-family: inherit;
      font-style: inherit;
      font-weight: inherit;
      font-size: inherit;
      text-decoration: none;
      color: inherit;
      transition: color 0.3s linear;

      &:hover {
        color: ${({ theme }) => theme.dyphiBlue};
      }
    }
  }

  .misc-box {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .locale {
      display: flex;
      padding: 0 30px;

      font-size: 1rem;

      button {
        font-family: inherit;
        font-style: inherit;
        font-weight: inherit;
        font-size: inherit;
        padding: 0 10px;
      }

      span {
        font-family: inherit;
        font-style: inherit;
        font-weight: inherit;
        font-size: inherit;
        color: ${({ theme }) => theme.dyphiInactive};
      }
    }

    .contact {
      font-family: inherit;
      font-style: inherit;
      font-weight: inherit;
      font-size: inherit;
      padding: 10px 20px;
      margin-right: 30px;
      color: ${({ theme }) => theme.dyphiBlue};
    }
  }
`;
