import styled from "styled-components";

export const StyledDrphy = styled.div`
  background-color: ${({ theme }) => theme.dyphiLightGray};

  display: flex;
  flex-direction: ${(props) => (props.isDesktop ? "row" : "column")};
  justify-content: space-around;
  align-items: center;
  min-height: 100vh;

  font-family: NanumSquare;
  font-style: normal;

  padding: 10vh 8.3333vw;

  .leftContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    min-width: ${(props) => (props.isDesktop ? "23.3332vw" : "100vw")};

    .logo {
      display: flex;
      justify-content: ${(props) =>
        props.isDesktop ? "flex-start" : "center"};
      align-items: center;
      margin-bottom: 5vh;

      img {
        width: 225px;
        height: auto;
      }
    }

    .content {
      .header {
        text-align: ${(props) => (props.isDesktop ? "left" : "center")};
        letter-spacing: 0.05rem;
        font-family: inherit;
        font-style: inherit;
        font-weight: 700;
        font-size: 3rem;
        line-height: 5rem;
        word-break: keep-all;
        color: ${({ theme }) => theme.dyphiBlueText};
        margin-bottom: 5vh;
      }

      .text {
        text-align: ${(props) => (props.isDesktop ? "left" : "center")};
        font-family: inherit;
        font-style: inherit;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 2rem;
        letter-spacing: 0.05rem;
        word-break: keep-all;
        color: ${({ theme }) => theme.dyphiBlack};
      }
    }

    .linkToMore {
      margin-top: 50px;
      text-align: ${(props) => (props.isDesktop ? "right" : "center")};
      padding: ${(props) => (props.isDesktop ? "10px" : "50px 10px 0px 10px")};

      a {
        text-decoration: none;
        font-family: inherit;
        font-style: inherit;
        font-weight: 700;
        font-size: 1.2rem;
        color: ${({ theme }) => theme.dyphiBlueText};
      }
    }
  }

  .rightGraphics {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10vh;

    img {
      max-height: ${(props) => (props.isDesktop ? "60vh" : "auto")};
      max-width: ${(props) => (props.isDesktop ? "auto" : "60vw")};
    }
  }
`;
