import React from "react";
import { useMediaQuery } from "react-responsive";
import { DesktopMinWidth } from "../../ScreenChecker";
import { FormattedMessage } from "react-intl";
import { Element } from "react-scroll";
import { Fade } from "react-awesome-reveal";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

import { StyledPublication } from "./Publication.styled";

const Publication = () => {
  const isDesktop = useMediaQuery({ minWidth: DesktopMinWidth });

  const animatedNumber = (number) => {
    return (
      <CountUp start={0} end={number} duration={3}>
        {({ countUpRef, start }) => (
          <VisibilitySensor onChange={start} delayedCall>
            <span ref={countUpRef} />
          </VisibilitySensor>
        )}
      </CountUp>
    );
  };

  return (
    <Element name="publication">
      <StyledPublication isDesktop={isDesktop}>
        <div className="title">
          <Fade delay={0} triggerOnce={true} direction="down">
            <FormattedMessage id="publication_header" />
          </Fade>
        </div>

        <div className="line"></div>

        <div className="items">
          <div className="item">
            <span className="value">
              {animatedNumber(4)}{" "}
              <span className="suffix">
                <FormattedMessage id="publication_suffix0" />
              </span>
            </span>

            <div className="suffix">
              <FormattedMessage id="publication_suffix1" />
            </div>
          </div>
          <div className="item">
            <span className="value">
              {animatedNumber(13)}{" "}
              <span className="suffix">
                <FormattedMessage id="publication_suffix0" />
              </span>
            </span>
            <div className="suffix">
              <FormattedMessage id="publication_suffix2" />
            </div>
          </div>
        </div>
        <div className="linkToMore">
          <a
            href="https://www.notion.so/dyphi/61ba8b3f0f4a4be0962ff4387415c064?v=3ada0bcf4e16409d8fe2ba381c3b8c93"
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage id="publication_more" />
            <span> &gt;</span>
          </a>
        </div>
      </StyledPublication>
    </Element>
  );
};

export default Publication;
