import React from "react";
import { useMediaQuery } from "react-responsive";
import { DesktopMinWidth, MobileMaxWidth } from "../../ScreenChecker";
import { FormattedMessage } from "react-intl";
import { StyledHome } from "./Home.styled";
import { Element } from "react-scroll";

const Home = () => {
  const isDesktop = useMediaQuery({ minWidth: DesktopMinWidth });
  const isMobile = useMediaQuery({ maxWidth: MobileMaxWidth });

  return (
    <Element name="home">
      <StyledHome
        isDesktop={isDesktop}
        backgroundImage={
          isMobile
            ? process.env.PUBLIC_URL + "/img/homepage-background-mobile.jpeg"
            : process.env.PUBLIC_URL + "/img/homepage-background-desktop.jpeg"
        }
      >
        <h1 className="title">
          <FormattedMessage id="home_title" />
        </h1>
      </StyledHome>
    </Element>
  );
};

export default Home;
