import styled from "styled-components";

export const StyledContact = styled.div`
  background-color: ${({ theme }) => theme.dyphiDarkGray};
  color: ${({ theme }) => theme.dyphiWhite};
  min-height: 100vh;
  padding: ${(props) =>
    props.isDesktop ? "8.3333vh 5vw 0vh 8.3333vw" : "0vh 5vw"};

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;

  font-family: NanumSquare;
  font-style: normal;

  .items {
    display: flex;
    flex-direction: column;

    .item {
      padding: 5vh 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: ${(props) => (props.isDesktop ? "flex-start" : "center")};

      .title {
        font-family: inherit;
        font-style: inherit;
        font-weight: 800;
        font-size: 2.5rem;
        letter-spacing: 0.1rem;
        line-height: 5rem;
        text-align: center;
      }

      .content {
        font-family: inherit;
        font-style: inherit;
        font-weight: 400;
        font-size: 1.2rem;
        letter-spacing: 0.05rem;
        line-height: 3rem;
        word-break: keep-all;
        text-align: center;
      }

      a.content {
        color: inherit;
        text-decoration: none;
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${(props) => (props.isDesktop ? "flex-end" : "center")};

    .copyright {
      font-family: inherit;
      font-style: inherit;
      font-weight: 400;
      font-size: 1rem;
      text-align: center;
      line-height: 2rem;
      letter-spacing: 0.05rem;
    }

    .temrs-and-conditions {
      display: flex;
      flex-direction: ${(props) => (props.isDesktop ? "row" : "column")};
      justify-content: center;
      align-items: center;
      letter-spacing: 0.05rem;

      .text {
        font-family: inherit;
        font-style: inherit;
        font-weight: 400;
        font-size: 1rem;
        line-height: 2rem;
        text-align: center;
        color: inherit;
        text-decoration: none;
      }

      .sep {
        padding: 0 0.5rem;
      }
    }
  }
`;
