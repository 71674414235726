import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { DesktopMinWidth } from "../../ScreenChecker";
import { StyledPrivateAndCookie } from "./PrivateAndCookie.styled";

import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import PrivateAndCookieDoc from "./PrivateAndCookie.md";

const PrivateAndCookie = () => {
  const isDesktop = useMediaQuery({ minWidth: DesktopMinWidth });

  const [markdown, setMarkdown] = useState("");

  const getMarkdown = async () => {
    fetch(PrivateAndCookieDoc)
      .then((res) => res.text())
      .then((text) => setMarkdown(text))
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getMarkdown();
  }, []);

  return (
    <StyledPrivateAndCookie isDesktop={isDesktop}>
      <div className="container">
        <div className="title">개인정보 처리방침</div>
        <div className="content">
          <ReactMarkdown remarkPlugins={[gfm]} children={markdown} />
        </div>
      </div>
    </StyledPrivateAndCookie>
  );
};

export default PrivateAndCookie;
