import React from "react";
import { useMediaQuery } from "react-responsive";
import { DesktopMinWidth } from "../../ScreenChecker";
import { FormattedMessage } from "react-intl";
import { Element } from "react-scroll";
import { Fade } from "react-awesome-reveal";

import { StyledSolution, StyledHeader, StyledText } from "./About.styled";

const About = () => {
  const isDesktop = useMediaQuery({ minWidth: DesktopMinWidth });

  return (
    <Element name="about">
      <StyledSolution isDesktop={isDesktop}>
        <div className="grid">
          <StyledHeader isDesktop={isDesktop} order={1}>
            <Fade delay={0} triggerOnce={true} direction="down">
              <FormattedMessage id="about_header1" />
            </Fade>
          </StyledHeader>
          <StyledText isDesktop={isDesktop} order={1}>
            <Fade delay={100} triggerOnce={true}>
              <FormattedMessage id="about_text1_1" />
              <FormattedMessage id="about_text1_2" />
            </Fade>
          </StyledText>

          <StyledHeader isDesktop={isDesktop} order={2}>
            <Fade delay={200} triggerOnce={true} direction="right">
              <FormattedMessage id="about_header2" />
            </Fade>
          </StyledHeader>
          <StyledText isDesktop={isDesktop} order={2}>
            <Fade delay={300} triggerOnce={true}>
              <FormattedMessage id="about_text2_1" />
              <FormattedMessage id="about_text2_2" />
            </Fade>
          </StyledText>

          <StyledHeader isDesktop={isDesktop} order={3}>
            <Fade delay={400} triggerOnce={true} direction="up">
              <FormattedMessage id="about_header3" />
            </Fade>
          </StyledHeader>
          <StyledText isDesktop={isDesktop} order={3}>
            <Fade delay={500} triggerOnce={true}>
              <FormattedMessage id="about_text3_1" />
              <FormattedMessage id="about_text3_2" />
            </Fade>
          </StyledText>
        </div>
      </StyledSolution>
    </Element>
  );
};

export default About;
